import filestack from "filestack-js";

let providerType = 'filestack';


let providers = {
    filestack: (options) => {
        const key = process.env.VUE_APP_USE_FILES_FILESTACK_API_KEY;
        const filestack = require('filestack-js');
 
        
        return {
            getClient: (options) => {
                try {
                    return  filestack.init(key, options)
                } catch (e) {
                    console.log(e);
                    return false;
                }
                
            },
            getPicker: async (clientOptions, pickerOptions) => {
                let client = await filestack.init(key, clientOptions);
                return client.picker(pickerOptions);
            },
        };
    }
};


let fileProvider = (options) => {
    return providers[providerType](options);
};

export default fileProvider;
